import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: ()=>import("../views/Home/Home.vue"),
    meta:{
      title: "对公银行开户-浦发银行-憨牛网"
    }
  },
  {
    path: "/form",
    name: "form",
    component: ()=>import("../views/Form/Form.vue"),
    meta:{
      title: "对公开户申请-浦发银行-憨牛网"
    }
  }
];

const router = new VueRouter({
  routes,
});

export default router;
