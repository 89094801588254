import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI  from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/basic.less"
import "lib-flexible"
import Vconsole from 'vconsole'

Vue.config.productionTip = false;

//测试环境打开调试
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'){
    let vConsole = new Vconsole()
    Vue.use(vConsole)
}

Vue.use(ElementUI)
Vue.use(require('vue-wechat-title'))
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
